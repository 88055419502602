import React from "react";
import { styled } from "../../stitches/stitches.config";

const Training = () => {
  return (
    <Container>
      <div
        className="app-placeholder-calendar"
        data-id="proj_VrEAvOytnVEN8JdX2F2kr"
      ></div>
    </Container>
  );
};

export default Training;

const Container = styled("div", {
  width: "95%",
  margin: "auto",
  height: 500,
  marginBottom: 50,
  "@md": {
    width: "100%",
  },
});
