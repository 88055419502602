import React from "react";
import { Helmet } from "react-helmet";
import HeadingAndByline from "../components/knowledgeCentre/HeadingAndByline";
import Training from "../components/knowledgeCentre/Training";
import GetInTouch from "../components/knowledgeCentre/training/GetInTouch";
import OurCourses from "../components/knowledgeCentre/training/OurCourses";
import KnowledgeCentrePage from "../templates/KnowledgeCentrePage";

const seo = {
  title: "Training Courses and Conferneces",
  description:
    "The Plastometrex team design and deliver technical short courses and conferences covering materials science, mechanics and mechanical testing. Courses are delivered in Cambridge led by Plastometrex Chief Scientific Officer, Professor Bill Clyne.",
};

const title = `Training & Conferences`;
const byline = `We design and deliver technical courses and conference events based in Cambridge, UK. Our courses cover everything from the fundamentals of mechanics and conventional materials testing techniques, all the way to new advanced methods, such as Indentation Plastometry.`;

function TrainingAndConferencesPage() {
  return (
    <KnowledgeCentrePage seo={seo} linkPath="/training-and-conferences">
      <Helmet>
        <script
          type="text/javascript"
          src="https://platform.eventscalendar.co/plugins/web/loader.js?app=calendar"
          id="Inffuse-Loader"
        ></script>
      </Helmet>
      <HeadingAndByline title={title} byline={byline} />

      <Training />
      <OurCourses />
      <GetInTouch />
    </KnowledgeCentrePage>
  );
}

export default TrainingAndConferencesPage;
