import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { detailMedium, heading100 } from "../../../stitches/combinedStyles";
import { styled } from "../../../stitches/stitches.config";

import EmailIcon from "../../shared/Newsletter/mailIcon.inline.svg";

const title = `Get in touch`;
const description = `If you have any questions about our courses and conferences contact our event administrator Rachael Moxon`;
const email = `r.moxon@plastometrex.com`;

const GetInTouch = () => {
  const data = useStaticQuery(graphql`
    query {
      render: file(relativePath: { eq: "get_in_touch.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 115, placeholder: BLURRED)
        }
      }
    }
  `);

  const image = getImage(data.render) as IGatsbyImageData;

  return (
    <Container>
      <ImageWrapper>
        <GatsbyImage image={image} alt="" />
      </ImageWrapper>
      <TextContent>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <a></a>
        <Email href={`mailto:${email}`}>
          <EmailIconSVG />
          {email}
        </Email>
      </TextContent>
    </Container>
  );
};

export default GetInTouch;

const Container = styled("div", {
  display: "flex",
  alignItems: "center",
  padding: "50px 0",
  width: "90%",
  margin: "auto",
  flexDirection: "column",
  textAlign: "center",
  "@sm": {
    flexDirection: "row",
    textAlign: "left",
  },
  "@lg": {
    width: "100%",
  },
});
const TextContent = styled("div", {
  "@sm": {
    marginLeft: 32,
  },
});
const ImageWrapper = styled("div", {
  borderRadius: 10,
  overflow: "hidden",
  width: 115,
  flex: "1 0 115px",
  "& img": {
    borderRadius: 10,
    overflow: "hidden",
  },
});
const Title = styled("h6", {
  ...heading100,
  margin: 0,
  marginTop: 20,
  "@sm": {
    margin: 0,
  },
});
const Description = styled("p", {
  ...detailMedium,
  margin: 0,
  color: "$black55",
  paddingBottom: 20,
});
const Email = styled("a", {
  fontFamily: "$inter",
  fontWeight: 600,
  fontSize: 18,
  lineHeight: "28px",
  color: "$blue100",
  marginBottom: 0,
});
const EmailIconSVG = styled(EmailIcon, {
  marginRight: 5,
});
