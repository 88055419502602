import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import {
  heading150,
  paragraphTextSmall,
} from "../../../stitches/combinedStyles";
import { styled } from "../../../stitches/stitches.config";

const OurCourses = () => {
  const data = useStaticQuery(graphql`
    query {
      render: file(relativePath: { eq: "collage.png" }) {
        childImageSharp {
          gatsbyImageData(width: 273, placeholder: BLURRED)
        }
      }
    }
  `);

  const image = getImage(data.render) as IGatsbyImageData;

  return (
    <Container>
      <TextContent>
        <Title>Our Courses</Title>
        <Description>
          All of our events are delivered by our team of world-leading materials
          scientists, led by our Chief Scientific Officer, Professor Bill Clyne.
          Courses are held in the historic city of Cambridge, UK and include
          accommodation in a university college, meals, lectures and
          demonstrations.
        </Description>
        <Description>
          If you are looking for an interesting course to advance your
          professional development or refresh your skills, then follow the links
          below to find out more.
        </Description>
      </TextContent>
      <ImageWrapper>
        <GatsbyImage
          image={image}
          alt="Plastometrex courses and events, covering materials science, mechanics and mechanical testing"
        />
      </ImageWrapper>
    </Container>
  );
};

export default OurCourses;

const Container = styled("div", {
  width: "90%",
  margin: "auto",
  maxWidth: 600,
  "@md": {
    display: "flex",
    maxWidth: "none",
    margin: "70px auto",
  },
  "@lg": {
    width: "100%",
  },
});
const TextContent = styled("div", {
  "@md": {
    marginRight: 50,
  },
});
const ImageWrapper = styled("div", {
  display: "none",
  flex: "1 0 40%",
  maxWidth: 273,
  "@md": {
    display: "block",
  },
});
const Title = styled("h4", {
  ...heading150,
});
const Description = styled("p", {
  ...paragraphTextSmall,
});
